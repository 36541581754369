import { Injectable, inject } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { AppConfigService } from '@fuse/services/config';
import { LoggerService } from './logger.service';

@Injectable({
    providedIn: 'root',
})
export class VersionCheckService {
    private _swUpdate: SwUpdate = inject(SwUpdate);
    private _config: AppConfigService = inject(AppConfigService);
    private _logger: LoggerService = inject(LoggerService);

    // constructor(public swUpdate: SwUpdate) {
    // if (updates.isEnabled) {
    //     interval(6 * 60 * 60).subscribe(() => updates.checkForUpdate()
    //         .then(() => console.log('checking for updates')));
    // }
    // }

    public checkForUpdates(): void {
        if (this._config.isVersionUpdated()) {
            this._config.unsetVersionUpdated();
            //TODO add this to localization
            this._logger.info('New version', 'Refreshed screen because of new app version');
        }
        //check version updated
        this._swUpdate.versionUpdates.subscribe((event) => {
            console.log(`Version updated: ${event.type}`);
            if (event.type !== 'NO_NEW_VERSION_DETECTED') {
                console.log('updating to new version');
                this._swUpdate.activateUpdate().then((reason) => {
                    console.log('updating to new version_ main method:' + reason);
                    if (reason) {
                        this._config.setVersionUpdated();
                        document.location.reload();
                    }
                });
            }
        });

        // if (this.swUpdate.isEnabled) {

        //     this.swUpdate.available.subscribe(() => {

        //         if(confirm("New version available. Load New Version?")) {

        //             window.location.reload();
        //         }
        //     });
        // }
    }
}
