import { EnvironmentProviders, Provider } from '@angular/core';
import { ClickOutsideDirective } from './click-outside.directive';
import { DateAgoPipe } from './date-ago.pipe';
import { MathAbsPipe } from './math-abs.pipe';
import { LocalDateTimePipe } from './local-datetime.pipe';


export const provideFrameworkPipes = (): Array<Provider | EnvironmentProviders> =>
{
    return [
        {
            provide: MathAbsPipe,
            useClass: MathAbsPipe
        },
        {
            provide: LocalDateTimePipe,
            useClass: LocalDateTimePipe
        },
        {
            provide: DateAgoPipe,
            useClass: DateAgoPipe
        },
        {
            provide: ClickOutsideDirective,
            useClass: ClickOutsideDirective
        },
    ];
};